import { default as _91marketHashName_93_45oldimmZJTXUWBMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName]-old.vue?macro=true";
import { default as _91marketHashName_93rdRRn7j9nXMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName].vue?macro=true";
import { default as chartskq7fZyTGfsMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/charts.vue?macro=true";
import { default as detail_45headerJxqzE0hBy1Meta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/detail-header.vue?macro=true";
import { default as item_45trackerciI86WffHmMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/item-tracker.vue?macro=true";
import { default as onsaleListxcVpzhD4YQMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/onsaleList.vue?macro=true";
import { default as price_45contentQQCuPRWXM7Meta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/price-content.vue?macro=true";
import { default as saleListv55S2ttHHeMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/saleList.vue?macro=true";
import { default as transactionDetailRxTWHrFRA1Meta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transactionDetail.vue?macro=true";
import { default as transicationtRjAVNRBEvMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transication.vue?macro=true";
import { default as wearRankp7CAhBD7lVMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/wearRank.vue?macro=true";
import { default as _91itemprint_93zNBdkwQVTRMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/tracker/[itemprint].vue?macro=true";
import { default as trackerItemk7Th6ghPXTMeta } from "/root/workspace/steamdt.com_3as3/pages/[game]/tracker/components/trackerItem.vue?macro=true";
import { default as indexEup6Lnfqb9Meta } from "/root/workspace/steamdt.com_3as3/pages/[game]/tracker/index.vue?macro=true";
import { default as indexLadder_45collectListSwjNzbmoEDMeta } from "/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/indexLadder-collectList.vue?macro=true";
import { default as indexLadder_45hangingListBXxLafYJ5EMeta } from "/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/indexLadder-hangingList.vue?macro=true";
import { default as indexLaddersupWfghxiS5Meta } from "/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/indexLadders.vue?macro=true";
import { default as sectionsO1O5faYENEMeta } from "/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/sections.vue?macro=true";
import { default as indexFQDHY8kMTyMeta } from "/root/workspace/steamdt.com_3as3/pages/case/index.vue?macro=true";
import { default as changes8dujYWcqHYMeta } from "/root/workspace/steamdt.com_3as3/pages/changes.vue?macro=true";
import { default as _404TWkCmWoAc8Meta } from "/root/workspace/steamdt.com_3as3/pages/error/404.vue?macro=true";
import { default as _500YYJKfS2szSMeta } from "/root/workspace/steamdt.com_3as3/pages/error/500.vue?macro=true";
import { default as index2FuuYcpRIYMeta } from "/root/workspace/steamdt.com_3as3/pages/faq/index.vue?macro=true";
import { default as indexFvsfSt3NZMMeta } from "/root/workspace/steamdt.com_3as3/pages/hanging/index.vue?macro=true";
import { default as index_45xinyingbackupICpYAFF9aAMeta } from "/root/workspace/steamdt.com_3as3/pages/index-xinyingbackup.vue?macro=true";
import { default as indexrfJzyOdIBPMeta } from "/root/workspace/steamdt.com_3as3/pages/index.vue?macro=true";
import { default as collect6vr93wt2qeMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/collect.vue?macro=true";
import { default as counterHoXkMYzb0aMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/counter.vue?macro=true";
import { default as indexChartInN09zylwwMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexChart.vue?macro=true";
import { default as indexDrawerT5yeT2AXZzMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexDrawer.vue?macro=true";
import { default as item_45change_45list90dpjTQfTHMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/item-change-list.vue?macro=true";
import { default as itemcompare5X46EmI0EPMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemcompare.vue?macro=true";
import { default as itemListf0ggPZVUDDMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemList.vue?macro=true";
import { default as klinechartsEhhdg416GrMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/klinecharts.vue?macro=true";
import { default as new_45index_45chart3iHVMzc362Meta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/new-index-chart.vue?macro=true";
import { default as online3wCcs0ogFgMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/online.vue?macro=true";
import { default as platformLowestPriceHw3fHRaNJuMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/platformLowestPrice.vue?macro=true";
import { default as scaleNumASTcg0OJeGMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/scaleNum.vue?macro=true";
import { default as transicationUfX7FWUzXdMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/transication.vue?macro=true";
import { default as update_45historyApuSb19KlCMeta } from "/root/workspace/steamdt.com_3as3/pages/indexcomponents/update-history.vue?macro=true";
import { default as _91steamId_93ejBKlYzldKMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/[steamId].vue?macro=true";
import { default as InventoryInfoO7TVLrnUSNMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryInfo.vue?macro=true";
import { default as inventoryitemcydpf5jVZhMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/components/inventoryitem.vue?macro=true";
import { default as InventorySearchAcAJ6bsFFHMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/components/InventorySearch.vue?macro=true";
import { default as InventoryToplVSRXXtSxKMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryTop.vue?macro=true";
import { default as SevenDaysInventoryqFExv7VYFqMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventory.vue?macro=true";
import { default as SevenDaysInventoryDetailnSgiBE6rgBMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventoryDetail.vue?macro=true";
import { default as indexKDk185p2imMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/index.vue?macro=true";
import { default as myGRehYgZpzFMeta } from "/root/workspace/steamdt.com_3as3/pages/inventory/my.vue?macro=true";
import { default as filter_45drawerO3CDCy8pQtMeta } from "/root/workspace/steamdt.com_3as3/pages/items/components/filter-drawer.vue?macro=true";
import { default as itemyoQDDbqNqIMeta } from "/root/workspace/steamdt.com_3as3/pages/items/components/item.vue?macro=true";
import { default as filter_45configeIKXcFwlpVMeta } from "/root/workspace/steamdt.com_3as3/pages/items/config/filter-config.js?macro=true";
import { default as indextVNQrDyT2zMeta } from "/root/workspace/steamdt.com_3as3/pages/items/index.vue?macro=true";
import { default as filter_45drawerPjQAyhIu0hMeta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/filter-drawer.vue?macro=true";
import { default as ladder_45center_45content2a6Rw8xgVIMeta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/ladder-center-content.vue?macro=true";
import { default as ladders_45lowestPricexVLmwCS7iAMeta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/ladders-lowestPrice.vue?macro=true";
import { default as price_45dropdownfwyA023jw3Meta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/price-dropdown.vue?macro=true";
import { default as sellNums_45dropdownPy8GwDJFDaMeta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/sellNums-dropdown.vue?macro=true";
import { default as swap_45pageSHorOX9WYzMeta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-page.vue?macro=true";
import { default as swap_45platform_45comparesoYNfJVyL6Meta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-platform-compare.vue?macro=true";
import { default as transication_45dropdownx8pT38LJ6MMeta } from "/root/workspace/steamdt.com_3as3/pages/ladders/components/transication-dropdown.vue?macro=true";
import { default as indexwfLcFeARXUMeta } from "/root/workspace/steamdt.com_3as3/pages/ladders/index.vue?macro=true";
import { default as bindSteamExUijIKNIsMeta } from "/root/workspace/steamdt.com_3as3/pages/login/bindSteam.vue?macro=true";
import { default as steamDKjznFPEztMeta } from "/root/workspace/steamdt.com_3as3/pages/login/steam.vue?macro=true";
import { default as filter_45drawerZPIaK5VSXvMeta } from "/root/workspace/steamdt.com_3as3/pages/mkt/components/filter-drawer.vue?macro=true";
import { default as itemCGeJcU6DuDMeta } from "/root/workspace/steamdt.com_3as3/pages/mkt/components/item.vue?macro=true";
import { default as platform_45content3xCptDi00aMeta } from "/root/workspace/steamdt.com_3as3/pages/mkt/components/platform-content.vue?macro=true";
import { default as filter_45configWZJUSYVdPxMeta } from "/root/workspace/steamdt.com_3as3/pages/mkt/config/filter-config.js?macro=true";
import { default as indexHgbfAsRuJKMeta } from "/root/workspace/steamdt.com_3as3/pages/mkt/index.vue?macro=true";
import { default as add_45collectRAvJ1eqvNIMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/add-collect.vue?macro=true";
import { default as add_45followhUsNxV6glWMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/add-follow.vue?macro=true";
import { default as collect_45chart2IbVNqvzqNMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-chart.vue?macro=true";
import { default as collect_45charts8Hpe54aCj0Meta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-charts.vue?macro=true";
import { default as collect_45detailjYPYy1Oc9lMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-detail.vue?macro=true";
import { default as collect_45manageTT06e7jAnsMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-manage.vue?macro=true";
import { default as compare_45follow94z23CtzzbMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/compare-follow.vue?macro=true";
import { default as create_45collectznCQhGNucUMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/create-collect.vue?macro=true";
import { default as delete_45collectxuUkr4zNTQMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/delete-collect.vue?macro=true";
import { default as detail_45item_45list9mHbvhGRzUMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/detail-item-list.vue?macro=true";
import { default as detail_45item689zM9cxx6Meta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/detail-item.vue?macro=true";
import { default as detail_45target_45tips4QxdStH3tqMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/detail-target-tips.vue?macro=true";
import { default as edit_45collect20ONHqdAhGMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/edit-collect.vue?macro=true";
import { default as filter_45contentCGKxXnEKerMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/filter-content.vue?macro=true";
import { default as follow_45dialog83Oc1CZUL5Meta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/follow-dialog.vue?macro=true";
import { default as inventory_45collect_45manage13AvqkBwrwMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/inventory-collect-manage.vue?macro=true";
import { default as inventory_45pageCPJrFXcog0Meta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/inventory-page.vue?macro=true";
import { default as item_45managep7yabEYULDMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/item-manage.vue?macro=true";
import { default as itemCollect_45listVoTGmwTWhmMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/itemCollect-list.vue?macro=true";
import { default as items_45pageZF2Cwtu89CMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/items-page.vue?macro=true";
import { default as set_45remark8l9By606UmMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/set-remark.vue?macro=true";
import { default as set_45targetPricexBHT4ZKUAmMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/set-targetPrice.vue?macro=true";
import { default as target_45tipsUJ5Qzr2CnjMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/target-tips.vue?macro=true";
import { default as unlogin_45contentUIe3NImbaYMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/components/unlogin-content.vue?macro=true";
import { default as filter_45configL4vKMNafmzMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/config/filter-config.js?macro=true";
import { default as indexWrlHVh1JaoMeta } from "/root/workspace/steamdt.com_3as3/pages/my/collect/index.vue?macro=true";
import { default as api8OTZ5sOxptMeta } from "/root/workspace/steamdt.com_3as3/pages/my/components/api.vue?macro=true";
import { default as bindEmail90TSUcgWnbMeta } from "/root/workspace/steamdt.com_3as3/pages/my/components/bindEmail.vue?macro=true";
import { default as changeAvatarDXZhGlyziLMeta } from "/root/workspace/steamdt.com_3as3/pages/my/components/changeAvatar.vue?macro=true";
import { default as changeEmailq88CAwNVs0Meta } from "/root/workspace/steamdt.com_3as3/pages/my/components/changeEmail.vue?macro=true";
import { default as changeNicknameFiZON9w2GMMeta } from "/root/workspace/steamdt.com_3as3/pages/my/components/changeNickname.vue?macro=true";
import { default as changePasswordtNTjY2k9LUMeta } from "/root/workspace/steamdt.com_3as3/pages/my/components/changePassword.vue?macro=true";
import { default as sideBarcGTULvrLmvMeta } from "/root/workspace/steamdt.com_3as3/pages/my/components/sideBar.vue?macro=true";
import { default as unbindSteamSJdQ7i5JvyMeta } from "/root/workspace/steamdt.com_3as3/pages/my/components/unbindSteam.vue?macro=true";
import { default as settingjFi5p7MneLMeta } from "/root/workspace/steamdt.com_3as3/pages/my/setting.vue?macro=true";
import { default as third_45bindfN3fIeqYKbMeta } from "/root/workspace/steamdt.com_3as3/pages/my/third-bind.vue?macro=true";
import { default as broad_45chart7wFuMQptd9Meta } from "/root/workspace/steamdt.com_3as3/pages/section/components/broad-chart.vue?macro=true";
import { default as hot_45section7ky03X5qrWMeta } from "/root/workspace/steamdt.com_3as3/pages/section/components/hot-section.vue?macro=true";
import { default as section_45chartRLH6aQKrYEMeta } from "/root/workspace/steamdt.com_3as3/pages/section/components/section-chart.vue?macro=true";
import { default as section_45platform6r77wHJZX7Meta } from "/root/workspace/steamdt.com_3as3/pages/section/components/section-platform.vue?macro=true";
import { default as indexppCA05Q0jiMeta } from "/root/workspace/steamdt.com_3as3/pages/section/index.vue?macro=true";
import { default as addFilterConditiondihcyLKygVMeta } from "/root/workspace/steamdt.com_3as3/pages/selection/components/addFilterCondition.vue?macro=true";
import { default as mkt_45valueViewerevFH4YaIkaMeta } from "/root/workspace/steamdt.com_3as3/pages/selection/components/mkt-valueViewer.vue?macro=true";
import { default as favoriterghmJJqlfLMeta } from "/root/workspace/steamdt.com_3as3/pages/selection/favorite.vue?macro=true";
import { default as indexvUvQkGCFn0Meta } from "/root/workspace/steamdt.com_3as3/pages/selection/index.vue?macro=true";
import { default as chooseSkinDialogmk5wPewZdjMeta } from "/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseSkinDialog.vue?macro=true";
import { default as chooseStickerDialog6R1roFN4afMeta } from "/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseStickerDialog.vue?macro=true";
import { default as itemzqI1eVG2KsMeta } from "/root/workspace/steamdt.com_3as3/pages/skindiy/components/item.vue?macro=true";
import { default as indexYie7rmbEG7Meta } from "/root/workspace/steamdt.com_3as3/pages/skindiy/index.vue?macro=true";
import { default as statusKfT2bRsdkUMeta } from "/root/workspace/steamdt.com_3as3/pages/status.vue?macro=true";
import { default as chartsRMb8V0pZ0iMeta } from "/root/workspace/steamdt.com_3as3/pages/statuscomponents/charts.vue?macro=true";
import { default as _91type_93b2rREbSfGXMeta } from "/root/workspace/steamdt.com_3as3/pages/terms/[type].vue?macro=true";
import { default as testX9tHwJMfw8Meta } from "/root/workspace/steamdt.com_3as3/pages/test.vue?macro=true";
import { default as indexbcN0HR41p8Meta } from "/root/workspace/steamdt.com_3as3/pages/trend/index.vue?macro=true";
import { default as dataItema1d2sUeq4ZMeta } from "/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/dataItem.vue?macro=true";
import { default as trendTbEIJ82LnXMeta } from "/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/trend.vue?macro=true";
import { default as updatedqV7MR5cdcMeta } from "/root/workspace/steamdt.com_3as3/pages/update.vue?macro=true";
export default [
  {
    name: "game-marketHashName-old___en",
    path: "/en/:game()/:marketHashName()-old",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName]-old.vue")
  },
  {
    name: "game-marketHashName-old___zh___default",
    path: "/:game()/:marketHashName()-old",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName]-old.vue")
  },
  {
    name: "game-marketHashName-old___zh",
    path: "/zh/:game()/:marketHashName()-old",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName]-old.vue")
  },
  {
    name: "game-marketHashName___en",
    path: "/en/:game()/:marketHashName()",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName].vue")
  },
  {
    name: "game-marketHashName___zh___default",
    path: "/:game()/:marketHashName()",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName].vue")
  },
  {
    name: "game-marketHashName___zh",
    path: "/zh/:game()/:marketHashName()",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/[marketHashName].vue")
  },
  {
    name: "game-itemDetailComponents-charts___en",
    path: "/en/:game()/itemDetailComponents/charts",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/charts.vue")
  },
  {
    name: "game-itemDetailComponents-charts___zh___default",
    path: "/:game()/itemDetailComponents/charts",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/charts.vue")
  },
  {
    name: "game-itemDetailComponents-charts___zh",
    path: "/zh/:game()/itemDetailComponents/charts",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/charts.vue")
  },
  {
    name: "game-itemDetailComponents-detail-header___en",
    path: "/en/:game()/itemDetailComponents/detail-header",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/detail-header.vue")
  },
  {
    name: "game-itemDetailComponents-detail-header___zh___default",
    path: "/:game()/itemDetailComponents/detail-header",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/detail-header.vue")
  },
  {
    name: "game-itemDetailComponents-detail-header___zh",
    path: "/zh/:game()/itemDetailComponents/detail-header",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/detail-header.vue")
  },
  {
    name: "game-itemDetailComponents-item-tracker___en",
    path: "/en/:game()/itemDetailComponents/item-tracker",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/item-tracker.vue")
  },
  {
    name: "game-itemDetailComponents-item-tracker___zh___default",
    path: "/:game()/itemDetailComponents/item-tracker",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/item-tracker.vue")
  },
  {
    name: "game-itemDetailComponents-item-tracker___zh",
    path: "/zh/:game()/itemDetailComponents/item-tracker",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/item-tracker.vue")
  },
  {
    name: "game-itemDetailComponents-onsaleList___en",
    path: "/en/:game()/itemDetailComponents/onsaleList",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/onsaleList.vue")
  },
  {
    name: "game-itemDetailComponents-onsaleList___zh___default",
    path: "/:game()/itemDetailComponents/onsaleList",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/onsaleList.vue")
  },
  {
    name: "game-itemDetailComponents-onsaleList___zh",
    path: "/zh/:game()/itemDetailComponents/onsaleList",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/onsaleList.vue")
  },
  {
    name: "game-itemDetailComponents-price-content___en",
    path: "/en/:game()/itemDetailComponents/price-content",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/price-content.vue")
  },
  {
    name: "game-itemDetailComponents-price-content___zh___default",
    path: "/:game()/itemDetailComponents/price-content",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/price-content.vue")
  },
  {
    name: "game-itemDetailComponents-price-content___zh",
    path: "/zh/:game()/itemDetailComponents/price-content",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/price-content.vue")
  },
  {
    name: "game-itemDetailComponents-saleList___en",
    path: "/en/:game()/itemDetailComponents/saleList",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/saleList.vue")
  },
  {
    name: "game-itemDetailComponents-saleList___zh___default",
    path: "/:game()/itemDetailComponents/saleList",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/saleList.vue")
  },
  {
    name: "game-itemDetailComponents-saleList___zh",
    path: "/zh/:game()/itemDetailComponents/saleList",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/saleList.vue")
  },
  {
    name: "game-itemDetailComponents-transactionDetail___en",
    path: "/en/:game()/itemDetailComponents/transactionDetail",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transactionDetail.vue")
  },
  {
    name: "game-itemDetailComponents-transactionDetail___zh___default",
    path: "/:game()/itemDetailComponents/transactionDetail",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transactionDetail.vue")
  },
  {
    name: "game-itemDetailComponents-transactionDetail___zh",
    path: "/zh/:game()/itemDetailComponents/transactionDetail",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transactionDetail.vue")
  },
  {
    name: "game-itemDetailComponents-transication___en",
    path: "/en/:game()/itemDetailComponents/transication",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transication.vue")
  },
  {
    name: "game-itemDetailComponents-transication___zh___default",
    path: "/:game()/itemDetailComponents/transication",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transication.vue")
  },
  {
    name: "game-itemDetailComponents-transication___zh",
    path: "/zh/:game()/itemDetailComponents/transication",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/transication.vue")
  },
  {
    name: "game-itemDetailComponents-wearRank___en",
    path: "/en/:game()/itemDetailComponents/wearRank",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/wearRank.vue")
  },
  {
    name: "game-itemDetailComponents-wearRank___zh___default",
    path: "/:game()/itemDetailComponents/wearRank",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/wearRank.vue")
  },
  {
    name: "game-itemDetailComponents-wearRank___zh",
    path: "/zh/:game()/itemDetailComponents/wearRank",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/itemDetailComponents/wearRank.vue")
  },
  {
    name: "game-tracker-itemprint___en",
    path: "/en/:game()/tracker/:itemprint()",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/[itemprint].vue")
  },
  {
    name: "game-tracker-itemprint___zh___default",
    path: "/:game()/tracker/:itemprint()",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/[itemprint].vue")
  },
  {
    name: "game-tracker-itemprint___zh",
    path: "/zh/:game()/tracker/:itemprint()",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/[itemprint].vue")
  },
  {
    name: "game-tracker-components-trackerItem___en",
    path: "/en/:game()/tracker/components/trackerItem",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/components/trackerItem.vue")
  },
  {
    name: "game-tracker-components-trackerItem___zh___default",
    path: "/:game()/tracker/components/trackerItem",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/components/trackerItem.vue")
  },
  {
    name: "game-tracker-components-trackerItem___zh",
    path: "/zh/:game()/tracker/components/trackerItem",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/components/trackerItem.vue")
  },
  {
    name: "game-tracker___en",
    path: "/en/:game()/tracker",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/index.vue")
  },
  {
    name: "game-tracker___zh___default",
    path: "/:game()/tracker",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/index.vue")
  },
  {
    name: "game-tracker___zh",
    path: "/zh/:game()/tracker",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/[game]/tracker/index.vue")
  },
  {
    name: "allnewIndexComponents-indexLadder-collectList___en",
    path: "/en/allnewIndexComponents/indexLadder-collectList",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/indexLadder-collectList.vue")
  },
  {
    name: "allnewIndexComponents-indexLadder-collectList___zh___default",
    path: "/allnewIndexComponents/indexLadder-collectList",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/indexLadder-collectList.vue")
  },
  {
    name: "allnewIndexComponents-indexLadder-collectList___zh",
    path: "/zh/allnewIndexComponents/indexLadder-collectList",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/indexLadder-collectList.vue")
  },
  {
    name: "allnewIndexComponents-indexLadder-hangingList___en",
    path: "/en/allnewIndexComponents/indexLadder-hangingList",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/indexLadder-hangingList.vue")
  },
  {
    name: "allnewIndexComponents-indexLadder-hangingList___zh___default",
    path: "/allnewIndexComponents/indexLadder-hangingList",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/indexLadder-hangingList.vue")
  },
  {
    name: "allnewIndexComponents-indexLadder-hangingList___zh",
    path: "/zh/allnewIndexComponents/indexLadder-hangingList",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/indexLadder-hangingList.vue")
  },
  {
    name: "allnewIndexComponents-indexLadders___en",
    path: "/en/allnewIndexComponents/indexLadders",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/indexLadders.vue")
  },
  {
    name: "allnewIndexComponents-indexLadders___zh___default",
    path: "/allnewIndexComponents/indexLadders",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/indexLadders.vue")
  },
  {
    name: "allnewIndexComponents-indexLadders___zh",
    path: "/zh/allnewIndexComponents/indexLadders",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/indexLadders.vue")
  },
  {
    name: "allnewIndexComponents-sections___en",
    path: "/en/allnewIndexComponents/sections",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/sections.vue")
  },
  {
    name: "allnewIndexComponents-sections___zh___default",
    path: "/allnewIndexComponents/sections",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/sections.vue")
  },
  {
    name: "allnewIndexComponents-sections___zh",
    path: "/zh/allnewIndexComponents/sections",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/allnewIndexComponents/sections.vue")
  },
  {
    name: "case___en",
    path: "/en/case",
    meta: indexFQDHY8kMTyMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/case/index.vue")
  },
  {
    name: "case___zh___default",
    path: "/case",
    meta: indexFQDHY8kMTyMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/case/index.vue")
  },
  {
    name: "case___zh",
    path: "/zh/case",
    meta: indexFQDHY8kMTyMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/case/index.vue")
  },
  {
    name: "changes___en",
    path: "/en/changes",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/changes.vue")
  },
  {
    name: "changes___zh___default",
    path: "/changes",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/changes.vue")
  },
  {
    name: "changes___zh",
    path: "/zh/changes",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/changes.vue")
  },
  {
    name: "error-404___en",
    path: "/en/error/404",
    meta: _404TWkCmWoAc8Meta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/error/404.vue")
  },
  {
    name: "error-404___zh___default",
    path: "/error/404",
    meta: _404TWkCmWoAc8Meta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/error/404.vue")
  },
  {
    name: "error-404___zh",
    path: "/zh/error/404",
    meta: _404TWkCmWoAc8Meta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/error/404.vue")
  },
  {
    name: "error-500___en",
    path: "/en/error/500",
    meta: _500YYJKfS2szSMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/error/500.vue")
  },
  {
    name: "error-500___zh___default",
    path: "/error/500",
    meta: _500YYJKfS2szSMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/error/500.vue")
  },
  {
    name: "error-500___zh",
    path: "/zh/error/500",
    meta: _500YYJKfS2szSMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/error/500.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/faq/index.vue")
  },
  {
    name: "faq___zh___default",
    path: "/faq",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/faq/index.vue")
  },
  {
    name: "faq___zh",
    path: "/zh/faq",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/faq/index.vue")
  },
  {
    name: "hanging___en",
    path: "/en/hanging",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/hanging/index.vue")
  },
  {
    name: "hanging___zh___default",
    path: "/hanging",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/hanging/index.vue")
  },
  {
    name: "hanging___zh",
    path: "/zh/hanging",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/hanging/index.vue")
  },
  {
    name: "index-xinyingbackup___en",
    path: "/en/index-xinyingbackup",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/index-xinyingbackup.vue")
  },
  {
    name: "index-xinyingbackup___zh___default",
    path: "/index-xinyingbackup",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/index-xinyingbackup.vue")
  },
  {
    name: "index-xinyingbackup___zh",
    path: "/zh/index-xinyingbackup",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/index-xinyingbackup.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/index.vue")
  },
  {
    name: "index___zh___default",
    path: "/",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/index.vue")
  },
  {
    name: "index___zh",
    path: "/zh",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/index.vue")
  },
  {
    name: "indexcomponents-collect___en",
    path: "/en/indexcomponents/collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/collect.vue")
  },
  {
    name: "indexcomponents-collect___zh___default",
    path: "/indexcomponents/collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/collect.vue")
  },
  {
    name: "indexcomponents-collect___zh",
    path: "/zh/indexcomponents/collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/collect.vue")
  },
  {
    name: "indexcomponents-counter___en",
    path: "/en/indexcomponents/counter",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/counter.vue")
  },
  {
    name: "indexcomponents-counter___zh___default",
    path: "/indexcomponents/counter",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/counter.vue")
  },
  {
    name: "indexcomponents-counter___zh",
    path: "/zh/indexcomponents/counter",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/counter.vue")
  },
  {
    name: "indexcomponents-indexChart___en",
    path: "/en/indexcomponents/indexChart",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexChart.vue")
  },
  {
    name: "indexcomponents-indexChart___zh___default",
    path: "/indexcomponents/indexChart",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexChart.vue")
  },
  {
    name: "indexcomponents-indexChart___zh",
    path: "/zh/indexcomponents/indexChart",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexChart.vue")
  },
  {
    name: "indexcomponents-indexDrawer___en",
    path: "/en/indexcomponents/indexDrawer",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexDrawer.vue")
  },
  {
    name: "indexcomponents-indexDrawer___zh___default",
    path: "/indexcomponents/indexDrawer",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexDrawer.vue")
  },
  {
    name: "indexcomponents-indexDrawer___zh",
    path: "/zh/indexcomponents/indexDrawer",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/indexDrawer.vue")
  },
  {
    name: "indexcomponents-item-change-list___en",
    path: "/en/indexcomponents/item-change-list",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/item-change-list.vue")
  },
  {
    name: "indexcomponents-item-change-list___zh___default",
    path: "/indexcomponents/item-change-list",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/item-change-list.vue")
  },
  {
    name: "indexcomponents-item-change-list___zh",
    path: "/zh/indexcomponents/item-change-list",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/item-change-list.vue")
  },
  {
    name: "indexcomponents-itemcompare___en",
    path: "/en/indexcomponents/itemcompare",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemcompare.vue")
  },
  {
    name: "indexcomponents-itemcompare___zh___default",
    path: "/indexcomponents/itemcompare",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemcompare.vue")
  },
  {
    name: "indexcomponents-itemcompare___zh",
    path: "/zh/indexcomponents/itemcompare",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemcompare.vue")
  },
  {
    name: "indexcomponents-itemList___en",
    path: "/en/indexcomponents/itemList",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemList.vue")
  },
  {
    name: "indexcomponents-itemList___zh___default",
    path: "/indexcomponents/itemList",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemList.vue")
  },
  {
    name: "indexcomponents-itemList___zh",
    path: "/zh/indexcomponents/itemList",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/itemList.vue")
  },
  {
    name: "indexcomponents-klinecharts___en",
    path: "/en/indexcomponents/klinecharts",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/klinecharts.vue")
  },
  {
    name: "indexcomponents-klinecharts___zh___default",
    path: "/indexcomponents/klinecharts",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/klinecharts.vue")
  },
  {
    name: "indexcomponents-klinecharts___zh",
    path: "/zh/indexcomponents/klinecharts",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/klinecharts.vue")
  },
  {
    name: "indexcomponents-new-index-chart___en",
    path: "/en/indexcomponents/new-index-chart",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/new-index-chart.vue")
  },
  {
    name: "indexcomponents-new-index-chart___zh___default",
    path: "/indexcomponents/new-index-chart",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/new-index-chart.vue")
  },
  {
    name: "indexcomponents-new-index-chart___zh",
    path: "/zh/indexcomponents/new-index-chart",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/new-index-chart.vue")
  },
  {
    name: "indexcomponents-online___en",
    path: "/en/indexcomponents/online",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/online.vue")
  },
  {
    name: "indexcomponents-online___zh___default",
    path: "/indexcomponents/online",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/online.vue")
  },
  {
    name: "indexcomponents-online___zh",
    path: "/zh/indexcomponents/online",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/online.vue")
  },
  {
    name: "indexcomponents-platformLowestPrice___en",
    path: "/en/indexcomponents/platformLowestPrice",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/platformLowestPrice.vue")
  },
  {
    name: "indexcomponents-platformLowestPrice___zh___default",
    path: "/indexcomponents/platformLowestPrice",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/platformLowestPrice.vue")
  },
  {
    name: "indexcomponents-platformLowestPrice___zh",
    path: "/zh/indexcomponents/platformLowestPrice",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/platformLowestPrice.vue")
  },
  {
    name: "indexcomponents-scaleNum___en",
    path: "/en/indexcomponents/scaleNum",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/scaleNum.vue")
  },
  {
    name: "indexcomponents-scaleNum___zh___default",
    path: "/indexcomponents/scaleNum",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/scaleNum.vue")
  },
  {
    name: "indexcomponents-scaleNum___zh",
    path: "/zh/indexcomponents/scaleNum",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/scaleNum.vue")
  },
  {
    name: "indexcomponents-transication___en",
    path: "/en/indexcomponents/transication",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/transication.vue")
  },
  {
    name: "indexcomponents-transication___zh___default",
    path: "/indexcomponents/transication",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/transication.vue")
  },
  {
    name: "indexcomponents-transication___zh",
    path: "/zh/indexcomponents/transication",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/transication.vue")
  },
  {
    name: "indexcomponents-update-history___en",
    path: "/en/indexcomponents/update-history",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/update-history.vue")
  },
  {
    name: "indexcomponents-update-history___zh___default",
    path: "/indexcomponents/update-history",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/update-history.vue")
  },
  {
    name: "indexcomponents-update-history___zh",
    path: "/zh/indexcomponents/update-history",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/indexcomponents/update-history.vue")
  },
  {
    name: "inventory-steamId___en",
    path: "/en/inventory/:steamId()",
    meta: _91steamId_93ejBKlYzldKMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/[steamId].vue")
  },
  {
    name: "inventory-steamId___zh___default",
    path: "/inventory/:steamId()",
    meta: _91steamId_93ejBKlYzldKMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/[steamId].vue")
  },
  {
    name: "inventory-steamId___zh",
    path: "/zh/inventory/:steamId()",
    meta: _91steamId_93ejBKlYzldKMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/[steamId].vue")
  },
  {
    name: "inventory-components-InventoryInfo___en",
    path: "/en/inventory/components/InventoryInfo",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryInfo.vue")
  },
  {
    name: "inventory-components-InventoryInfo___zh___default",
    path: "/inventory/components/InventoryInfo",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryInfo.vue")
  },
  {
    name: "inventory-components-InventoryInfo___zh",
    path: "/zh/inventory/components/InventoryInfo",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryInfo.vue")
  },
  {
    name: "inventory-components-inventoryitem___en",
    path: "/en/inventory/components/inventoryitem",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/inventoryitem.vue")
  },
  {
    name: "inventory-components-inventoryitem___zh___default",
    path: "/inventory/components/inventoryitem",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/inventoryitem.vue")
  },
  {
    name: "inventory-components-inventoryitem___zh",
    path: "/zh/inventory/components/inventoryitem",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/inventoryitem.vue")
  },
  {
    name: "inventory-components-InventorySearch___en",
    path: "/en/inventory/components/InventorySearch",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventorySearch.vue")
  },
  {
    name: "inventory-components-InventorySearch___zh___default",
    path: "/inventory/components/InventorySearch",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventorySearch.vue")
  },
  {
    name: "inventory-components-InventorySearch___zh",
    path: "/zh/inventory/components/InventorySearch",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventorySearch.vue")
  },
  {
    name: "inventory-components-InventoryTop___en",
    path: "/en/inventory/components/InventoryTop",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryTop.vue")
  },
  {
    name: "inventory-components-InventoryTop___zh___default",
    path: "/inventory/components/InventoryTop",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryTop.vue")
  },
  {
    name: "inventory-components-InventoryTop___zh",
    path: "/zh/inventory/components/InventoryTop",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/InventoryTop.vue")
  },
  {
    name: "inventory-components-SevenDaysInventory___en",
    path: "/en/inventory/components/SevenDaysInventory",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventory.vue")
  },
  {
    name: "inventory-components-SevenDaysInventory___zh___default",
    path: "/inventory/components/SevenDaysInventory",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventory.vue")
  },
  {
    name: "inventory-components-SevenDaysInventory___zh",
    path: "/zh/inventory/components/SevenDaysInventory",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventory.vue")
  },
  {
    name: "inventory-components-SevenDaysInventoryDetail___en",
    path: "/en/inventory/components/SevenDaysInventoryDetail",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventoryDetail.vue")
  },
  {
    name: "inventory-components-SevenDaysInventoryDetail___zh___default",
    path: "/inventory/components/SevenDaysInventoryDetail",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventoryDetail.vue")
  },
  {
    name: "inventory-components-SevenDaysInventoryDetail___zh",
    path: "/zh/inventory/components/SevenDaysInventoryDetail",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/components/SevenDaysInventoryDetail.vue")
  },
  {
    name: "inventory___en",
    path: "/en/inventory",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/index.vue")
  },
  {
    name: "inventory___zh___default",
    path: "/inventory",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/index.vue")
  },
  {
    name: "inventory___zh",
    path: "/zh/inventory",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/index.vue")
  },
  {
    name: "inventory-my___en",
    path: "/en/inventory/my",
    meta: myGRehYgZpzFMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/my.vue")
  },
  {
    name: "inventory-my___zh___default",
    path: "/inventory/my",
    meta: myGRehYgZpzFMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/my.vue")
  },
  {
    name: "inventory-my___zh",
    path: "/zh/inventory/my",
    meta: myGRehYgZpzFMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/inventory/my.vue")
  },
  {
    name: "items-components-filter-drawer___en",
    path: "/en/items/components/filter-drawer",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/items/components/filter-drawer.vue")
  },
  {
    name: "items-components-filter-drawer___zh___default",
    path: "/items/components/filter-drawer",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/items/components/filter-drawer.vue")
  },
  {
    name: "items-components-filter-drawer___zh",
    path: "/zh/items/components/filter-drawer",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/items/components/filter-drawer.vue")
  },
  {
    name: "items-components-item___en",
    path: "/en/items/components/item",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/items/components/item.vue")
  },
  {
    name: "items-components-item___zh___default",
    path: "/items/components/item",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/items/components/item.vue")
  },
  {
    name: "items-components-item___zh",
    path: "/zh/items/components/item",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/items/components/item.vue")
  },
  {
    name: "items-config-filter-config___en",
    path: "/en/items/config/filter-config",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/items/config/filter-config.js")
  },
  {
    name: "items-config-filter-config___zh___default",
    path: "/items/config/filter-config",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/items/config/filter-config.js")
  },
  {
    name: "items-config-filter-config___zh",
    path: "/zh/items/config/filter-config",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/items/config/filter-config.js")
  },
  {
    name: "items___en",
    path: "/en/items",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/items/index.vue")
  },
  {
    name: "items___zh___default",
    path: "/items",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/items/index.vue")
  },
  {
    name: "items___zh",
    path: "/zh/items",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/items/index.vue")
  },
  {
    name: "ladders-components-filter-drawer___en",
    path: "/en/ladders/components/filter-drawer",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/filter-drawer.vue")
  },
  {
    name: "ladders-components-filter-drawer___zh___default",
    path: "/ladders/components/filter-drawer",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/filter-drawer.vue")
  },
  {
    name: "ladders-components-filter-drawer___zh",
    path: "/zh/ladders/components/filter-drawer",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/filter-drawer.vue")
  },
  {
    name: "ladders-components-ladder-center-content___en",
    path: "/en/ladders/components/ladder-center-content",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/ladder-center-content.vue")
  },
  {
    name: "ladders-components-ladder-center-content___zh___default",
    path: "/ladders/components/ladder-center-content",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/ladder-center-content.vue")
  },
  {
    name: "ladders-components-ladder-center-content___zh",
    path: "/zh/ladders/components/ladder-center-content",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/ladder-center-content.vue")
  },
  {
    name: "ladders-components-ladders-lowestPrice___en",
    path: "/en/ladders/components/ladders-lowestPrice",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/ladders-lowestPrice.vue")
  },
  {
    name: "ladders-components-ladders-lowestPrice___zh___default",
    path: "/ladders/components/ladders-lowestPrice",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/ladders-lowestPrice.vue")
  },
  {
    name: "ladders-components-ladders-lowestPrice___zh",
    path: "/zh/ladders/components/ladders-lowestPrice",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/ladders-lowestPrice.vue")
  },
  {
    name: "ladders-components-price-dropdown___en",
    path: "/en/ladders/components/price-dropdown",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/price-dropdown.vue")
  },
  {
    name: "ladders-components-price-dropdown___zh___default",
    path: "/ladders/components/price-dropdown",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/price-dropdown.vue")
  },
  {
    name: "ladders-components-price-dropdown___zh",
    path: "/zh/ladders/components/price-dropdown",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/price-dropdown.vue")
  },
  {
    name: "ladders-components-sellNums-dropdown___en",
    path: "/en/ladders/components/sellNums-dropdown",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/sellNums-dropdown.vue")
  },
  {
    name: "ladders-components-sellNums-dropdown___zh___default",
    path: "/ladders/components/sellNums-dropdown",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/sellNums-dropdown.vue")
  },
  {
    name: "ladders-components-sellNums-dropdown___zh",
    path: "/zh/ladders/components/sellNums-dropdown",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/sellNums-dropdown.vue")
  },
  {
    name: "ladders-components-swap-page___en",
    path: "/en/ladders/components/swap-page",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-page.vue")
  },
  {
    name: "ladders-components-swap-page___zh___default",
    path: "/ladders/components/swap-page",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-page.vue")
  },
  {
    name: "ladders-components-swap-page___zh",
    path: "/zh/ladders/components/swap-page",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-page.vue")
  },
  {
    name: "ladders-components-swap-platform-compare___en",
    path: "/en/ladders/components/swap-platform-compare",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-platform-compare.vue")
  },
  {
    name: "ladders-components-swap-platform-compare___zh___default",
    path: "/ladders/components/swap-platform-compare",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-platform-compare.vue")
  },
  {
    name: "ladders-components-swap-platform-compare___zh",
    path: "/zh/ladders/components/swap-platform-compare",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/swap-platform-compare.vue")
  },
  {
    name: "ladders-components-transication-dropdown___en",
    path: "/en/ladders/components/transication-dropdown",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/transication-dropdown.vue")
  },
  {
    name: "ladders-components-transication-dropdown___zh___default",
    path: "/ladders/components/transication-dropdown",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/transication-dropdown.vue")
  },
  {
    name: "ladders-components-transication-dropdown___zh",
    path: "/zh/ladders/components/transication-dropdown",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/components/transication-dropdown.vue")
  },
  {
    name: "ladders___en",
    path: "/en/ladders",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/index.vue")
  },
  {
    name: "ladders___zh___default",
    path: "/ladders",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/index.vue")
  },
  {
    name: "ladders___zh",
    path: "/zh/ladders",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/ladders/index.vue")
  },
  {
    name: "login-bindSteam___en",
    path: "/en/login/bindSteam",
    meta: bindSteamExUijIKNIsMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/login/bindSteam.vue")
  },
  {
    name: "login-bindSteam___zh___default",
    path: "/login/bindSteam",
    meta: bindSteamExUijIKNIsMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/login/bindSteam.vue")
  },
  {
    name: "login-bindSteam___zh",
    path: "/zh/login/bindSteam",
    meta: bindSteamExUijIKNIsMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/login/bindSteam.vue")
  },
  {
    name: "login-steam___en",
    path: "/en/login/steam",
    meta: steamDKjznFPEztMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/login/steam.vue")
  },
  {
    name: "login-steam___zh___default",
    path: "/login/steam",
    meta: steamDKjznFPEztMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/login/steam.vue")
  },
  {
    name: "login-steam___zh",
    path: "/zh/login/steam",
    meta: steamDKjznFPEztMeta || {},
    component: () => import("/root/workspace/steamdt.com_3as3/pages/login/steam.vue")
  },
  {
    name: "mkt-components-filter-drawer___en",
    path: "/en/mkt/components/filter-drawer",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/components/filter-drawer.vue")
  },
  {
    name: "mkt-components-filter-drawer___zh___default",
    path: "/mkt/components/filter-drawer",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/components/filter-drawer.vue")
  },
  {
    name: "mkt-components-filter-drawer___zh",
    path: "/zh/mkt/components/filter-drawer",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/components/filter-drawer.vue")
  },
  {
    name: "mkt-components-item___en",
    path: "/en/mkt/components/item",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/components/item.vue")
  },
  {
    name: "mkt-components-item___zh___default",
    path: "/mkt/components/item",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/components/item.vue")
  },
  {
    name: "mkt-components-item___zh",
    path: "/zh/mkt/components/item",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/components/item.vue")
  },
  {
    name: "mkt-components-platform-content___en",
    path: "/en/mkt/components/platform-content",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/components/platform-content.vue")
  },
  {
    name: "mkt-components-platform-content___zh___default",
    path: "/mkt/components/platform-content",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/components/platform-content.vue")
  },
  {
    name: "mkt-components-platform-content___zh",
    path: "/zh/mkt/components/platform-content",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/components/platform-content.vue")
  },
  {
    name: "mkt-config-filter-config___en",
    path: "/en/mkt/config/filter-config",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/config/filter-config.js")
  },
  {
    name: "mkt-config-filter-config___zh___default",
    path: "/mkt/config/filter-config",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/config/filter-config.js")
  },
  {
    name: "mkt-config-filter-config___zh",
    path: "/zh/mkt/config/filter-config",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/config/filter-config.js")
  },
  {
    name: "mkt___en",
    path: "/en/mkt",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/index.vue")
  },
  {
    name: "mkt___zh___default",
    path: "/mkt",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/index.vue")
  },
  {
    name: "mkt___zh",
    path: "/zh/mkt",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/mkt/index.vue")
  },
  {
    name: "my-collect-components-add-collect___en",
    path: "/en/my/collect/components/add-collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/add-collect.vue")
  },
  {
    name: "my-collect-components-add-collect___zh___default",
    path: "/my/collect/components/add-collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/add-collect.vue")
  },
  {
    name: "my-collect-components-add-collect___zh",
    path: "/zh/my/collect/components/add-collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/add-collect.vue")
  },
  {
    name: "my-collect-components-add-follow___en",
    path: "/en/my/collect/components/add-follow",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/add-follow.vue")
  },
  {
    name: "my-collect-components-add-follow___zh___default",
    path: "/my/collect/components/add-follow",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/add-follow.vue")
  },
  {
    name: "my-collect-components-add-follow___zh",
    path: "/zh/my/collect/components/add-follow",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/add-follow.vue")
  },
  {
    name: "my-collect-components-collect-chart___en",
    path: "/en/my/collect/components/collect-chart",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-chart.vue")
  },
  {
    name: "my-collect-components-collect-chart___zh___default",
    path: "/my/collect/components/collect-chart",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-chart.vue")
  },
  {
    name: "my-collect-components-collect-chart___zh",
    path: "/zh/my/collect/components/collect-chart",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-chart.vue")
  },
  {
    name: "my-collect-components-collect-charts___en",
    path: "/en/my/collect/components/collect-charts",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-charts.vue")
  },
  {
    name: "my-collect-components-collect-charts___zh___default",
    path: "/my/collect/components/collect-charts",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-charts.vue")
  },
  {
    name: "my-collect-components-collect-charts___zh",
    path: "/zh/my/collect/components/collect-charts",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-charts.vue")
  },
  {
    name: "my-collect-components-collect-detail___en",
    path: "/en/my/collect/components/collect-detail",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-detail.vue")
  },
  {
    name: "my-collect-components-collect-detail___zh___default",
    path: "/my/collect/components/collect-detail",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-detail.vue")
  },
  {
    name: "my-collect-components-collect-detail___zh",
    path: "/zh/my/collect/components/collect-detail",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-detail.vue")
  },
  {
    name: "my-collect-components-collect-manage___en",
    path: "/en/my/collect/components/collect-manage",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-manage.vue")
  },
  {
    name: "my-collect-components-collect-manage___zh___default",
    path: "/my/collect/components/collect-manage",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-manage.vue")
  },
  {
    name: "my-collect-components-collect-manage___zh",
    path: "/zh/my/collect/components/collect-manage",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/collect-manage.vue")
  },
  {
    name: "my-collect-components-compare-follow___en",
    path: "/en/my/collect/components/compare-follow",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/compare-follow.vue")
  },
  {
    name: "my-collect-components-compare-follow___zh___default",
    path: "/my/collect/components/compare-follow",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/compare-follow.vue")
  },
  {
    name: "my-collect-components-compare-follow___zh",
    path: "/zh/my/collect/components/compare-follow",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/compare-follow.vue")
  },
  {
    name: "my-collect-components-create-collect___en",
    path: "/en/my/collect/components/create-collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/create-collect.vue")
  },
  {
    name: "my-collect-components-create-collect___zh___default",
    path: "/my/collect/components/create-collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/create-collect.vue")
  },
  {
    name: "my-collect-components-create-collect___zh",
    path: "/zh/my/collect/components/create-collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/create-collect.vue")
  },
  {
    name: "my-collect-components-delete-collect___en",
    path: "/en/my/collect/components/delete-collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/delete-collect.vue")
  },
  {
    name: "my-collect-components-delete-collect___zh___default",
    path: "/my/collect/components/delete-collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/delete-collect.vue")
  },
  {
    name: "my-collect-components-delete-collect___zh",
    path: "/zh/my/collect/components/delete-collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/delete-collect.vue")
  },
  {
    name: "my-collect-components-detail-item-list___en",
    path: "/en/my/collect/components/detail-item-list",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/detail-item-list.vue")
  },
  {
    name: "my-collect-components-detail-item-list___zh___default",
    path: "/my/collect/components/detail-item-list",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/detail-item-list.vue")
  },
  {
    name: "my-collect-components-detail-item-list___zh",
    path: "/zh/my/collect/components/detail-item-list",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/detail-item-list.vue")
  },
  {
    name: "my-collect-components-detail-item___en",
    path: "/en/my/collect/components/detail-item",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/detail-item.vue")
  },
  {
    name: "my-collect-components-detail-item___zh___default",
    path: "/my/collect/components/detail-item",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/detail-item.vue")
  },
  {
    name: "my-collect-components-detail-item___zh",
    path: "/zh/my/collect/components/detail-item",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/detail-item.vue")
  },
  {
    name: "my-collect-components-detail-target-tips___en",
    path: "/en/my/collect/components/detail-target-tips",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/detail-target-tips.vue")
  },
  {
    name: "my-collect-components-detail-target-tips___zh___default",
    path: "/my/collect/components/detail-target-tips",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/detail-target-tips.vue")
  },
  {
    name: "my-collect-components-detail-target-tips___zh",
    path: "/zh/my/collect/components/detail-target-tips",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/detail-target-tips.vue")
  },
  {
    name: "my-collect-components-edit-collect___en",
    path: "/en/my/collect/components/edit-collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/edit-collect.vue")
  },
  {
    name: "my-collect-components-edit-collect___zh___default",
    path: "/my/collect/components/edit-collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/edit-collect.vue")
  },
  {
    name: "my-collect-components-edit-collect___zh",
    path: "/zh/my/collect/components/edit-collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/edit-collect.vue")
  },
  {
    name: "my-collect-components-filter-content___en",
    path: "/en/my/collect/components/filter-content",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/filter-content.vue")
  },
  {
    name: "my-collect-components-filter-content___zh___default",
    path: "/my/collect/components/filter-content",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/filter-content.vue")
  },
  {
    name: "my-collect-components-filter-content___zh",
    path: "/zh/my/collect/components/filter-content",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/filter-content.vue")
  },
  {
    name: "my-collect-components-follow-dialog___en",
    path: "/en/my/collect/components/follow-dialog",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/follow-dialog.vue")
  },
  {
    name: "my-collect-components-follow-dialog___zh___default",
    path: "/my/collect/components/follow-dialog",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/follow-dialog.vue")
  },
  {
    name: "my-collect-components-follow-dialog___zh",
    path: "/zh/my/collect/components/follow-dialog",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/follow-dialog.vue")
  },
  {
    name: "my-collect-components-inventory-collect-manage___en",
    path: "/en/my/collect/components/inventory-collect-manage",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/inventory-collect-manage.vue")
  },
  {
    name: "my-collect-components-inventory-collect-manage___zh___default",
    path: "/my/collect/components/inventory-collect-manage",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/inventory-collect-manage.vue")
  },
  {
    name: "my-collect-components-inventory-collect-manage___zh",
    path: "/zh/my/collect/components/inventory-collect-manage",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/inventory-collect-manage.vue")
  },
  {
    name: "my-collect-components-inventory-page___en",
    path: "/en/my/collect/components/inventory-page",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/inventory-page.vue")
  },
  {
    name: "my-collect-components-inventory-page___zh___default",
    path: "/my/collect/components/inventory-page",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/inventory-page.vue")
  },
  {
    name: "my-collect-components-inventory-page___zh",
    path: "/zh/my/collect/components/inventory-page",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/inventory-page.vue")
  },
  {
    name: "my-collect-components-item-manage___en",
    path: "/en/my/collect/components/item-manage",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/item-manage.vue")
  },
  {
    name: "my-collect-components-item-manage___zh___default",
    path: "/my/collect/components/item-manage",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/item-manage.vue")
  },
  {
    name: "my-collect-components-item-manage___zh",
    path: "/zh/my/collect/components/item-manage",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/item-manage.vue")
  },
  {
    name: "my-collect-components-itemCollect-list___en",
    path: "/en/my/collect/components/itemCollect-list",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/itemCollect-list.vue")
  },
  {
    name: "my-collect-components-itemCollect-list___zh___default",
    path: "/my/collect/components/itemCollect-list",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/itemCollect-list.vue")
  },
  {
    name: "my-collect-components-itemCollect-list___zh",
    path: "/zh/my/collect/components/itemCollect-list",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/itemCollect-list.vue")
  },
  {
    name: "my-collect-components-items-page___en",
    path: "/en/my/collect/components/items-page",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/items-page.vue")
  },
  {
    name: "my-collect-components-items-page___zh___default",
    path: "/my/collect/components/items-page",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/items-page.vue")
  },
  {
    name: "my-collect-components-items-page___zh",
    path: "/zh/my/collect/components/items-page",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/items-page.vue")
  },
  {
    name: "my-collect-components-set-remark___en",
    path: "/en/my/collect/components/set-remark",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/set-remark.vue")
  },
  {
    name: "my-collect-components-set-remark___zh___default",
    path: "/my/collect/components/set-remark",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/set-remark.vue")
  },
  {
    name: "my-collect-components-set-remark___zh",
    path: "/zh/my/collect/components/set-remark",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/set-remark.vue")
  },
  {
    name: "my-collect-components-set-targetPrice___en",
    path: "/en/my/collect/components/set-targetPrice",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/set-targetPrice.vue")
  },
  {
    name: "my-collect-components-set-targetPrice___zh___default",
    path: "/my/collect/components/set-targetPrice",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/set-targetPrice.vue")
  },
  {
    name: "my-collect-components-set-targetPrice___zh",
    path: "/zh/my/collect/components/set-targetPrice",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/set-targetPrice.vue")
  },
  {
    name: "my-collect-components-target-tips___en",
    path: "/en/my/collect/components/target-tips",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/target-tips.vue")
  },
  {
    name: "my-collect-components-target-tips___zh___default",
    path: "/my/collect/components/target-tips",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/target-tips.vue")
  },
  {
    name: "my-collect-components-target-tips___zh",
    path: "/zh/my/collect/components/target-tips",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/target-tips.vue")
  },
  {
    name: "my-collect-components-unlogin-content___en",
    path: "/en/my/collect/components/unlogin-content",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/unlogin-content.vue")
  },
  {
    name: "my-collect-components-unlogin-content___zh___default",
    path: "/my/collect/components/unlogin-content",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/unlogin-content.vue")
  },
  {
    name: "my-collect-components-unlogin-content___zh",
    path: "/zh/my/collect/components/unlogin-content",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/components/unlogin-content.vue")
  },
  {
    name: "my-collect-config-filter-config___en",
    path: "/en/my/collect/config/filter-config",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/config/filter-config.js")
  },
  {
    name: "my-collect-config-filter-config___zh___default",
    path: "/my/collect/config/filter-config",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/config/filter-config.js")
  },
  {
    name: "my-collect-config-filter-config___zh",
    path: "/zh/my/collect/config/filter-config",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/config/filter-config.js")
  },
  {
    name: "my-collect___en",
    path: "/en/my/collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/index.vue")
  },
  {
    name: "my-collect___zh___default",
    path: "/my/collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/index.vue")
  },
  {
    name: "my-collect___zh",
    path: "/zh/my/collect",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/collect/index.vue")
  },
  {
    name: "my-components-api___en",
    path: "/en/my/components/api",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/api.vue")
  },
  {
    name: "my-components-api___zh___default",
    path: "/my/components/api",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/api.vue")
  },
  {
    name: "my-components-api___zh",
    path: "/zh/my/components/api",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/api.vue")
  },
  {
    name: "my-components-bindEmail___en",
    path: "/en/my/components/bindEmail",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/bindEmail.vue")
  },
  {
    name: "my-components-bindEmail___zh___default",
    path: "/my/components/bindEmail",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/bindEmail.vue")
  },
  {
    name: "my-components-bindEmail___zh",
    path: "/zh/my/components/bindEmail",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/bindEmail.vue")
  },
  {
    name: "my-components-changeAvatar___en",
    path: "/en/my/components/changeAvatar",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/changeAvatar.vue")
  },
  {
    name: "my-components-changeAvatar___zh___default",
    path: "/my/components/changeAvatar",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/changeAvatar.vue")
  },
  {
    name: "my-components-changeAvatar___zh",
    path: "/zh/my/components/changeAvatar",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/changeAvatar.vue")
  },
  {
    name: "my-components-changeEmail___en",
    path: "/en/my/components/changeEmail",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/changeEmail.vue")
  },
  {
    name: "my-components-changeEmail___zh___default",
    path: "/my/components/changeEmail",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/changeEmail.vue")
  },
  {
    name: "my-components-changeEmail___zh",
    path: "/zh/my/components/changeEmail",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/changeEmail.vue")
  },
  {
    name: "my-components-changeNickname___en",
    path: "/en/my/components/changeNickname",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/changeNickname.vue")
  },
  {
    name: "my-components-changeNickname___zh___default",
    path: "/my/components/changeNickname",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/changeNickname.vue")
  },
  {
    name: "my-components-changeNickname___zh",
    path: "/zh/my/components/changeNickname",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/changeNickname.vue")
  },
  {
    name: "my-components-changePassword___en",
    path: "/en/my/components/changePassword",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/changePassword.vue")
  },
  {
    name: "my-components-changePassword___zh___default",
    path: "/my/components/changePassword",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/changePassword.vue")
  },
  {
    name: "my-components-changePassword___zh",
    path: "/zh/my/components/changePassword",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/changePassword.vue")
  },
  {
    name: "my-components-sideBar___en",
    path: "/en/my/components/sideBar",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/sideBar.vue")
  },
  {
    name: "my-components-sideBar___zh___default",
    path: "/my/components/sideBar",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/sideBar.vue")
  },
  {
    name: "my-components-sideBar___zh",
    path: "/zh/my/components/sideBar",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/sideBar.vue")
  },
  {
    name: "my-components-unbindSteam___en",
    path: "/en/my/components/unbindSteam",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/unbindSteam.vue")
  },
  {
    name: "my-components-unbindSteam___zh___default",
    path: "/my/components/unbindSteam",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/unbindSteam.vue")
  },
  {
    name: "my-components-unbindSteam___zh",
    path: "/zh/my/components/unbindSteam",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/components/unbindSteam.vue")
  },
  {
    name: "my-setting___en",
    path: "/en/my/setting",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/setting.vue")
  },
  {
    name: "my-setting___zh___default",
    path: "/my/setting",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/setting.vue")
  },
  {
    name: "my-setting___zh",
    path: "/zh/my/setting",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/setting.vue")
  },
  {
    name: "my-third-bind___en",
    path: "/en/my/third-bind",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/third-bind.vue")
  },
  {
    name: "my-third-bind___zh___default",
    path: "/my/third-bind",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/third-bind.vue")
  },
  {
    name: "my-third-bind___zh",
    path: "/zh/my/third-bind",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/my/third-bind.vue")
  },
  {
    name: "section-components-broad-chart___en",
    path: "/en/section/components/broad-chart",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/section/components/broad-chart.vue")
  },
  {
    name: "section-components-broad-chart___zh___default",
    path: "/section/components/broad-chart",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/section/components/broad-chart.vue")
  },
  {
    name: "section-components-broad-chart___zh",
    path: "/zh/section/components/broad-chart",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/section/components/broad-chart.vue")
  },
  {
    name: "section-components-hot-section___en",
    path: "/en/section/components/hot-section",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/section/components/hot-section.vue")
  },
  {
    name: "section-components-hot-section___zh___default",
    path: "/section/components/hot-section",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/section/components/hot-section.vue")
  },
  {
    name: "section-components-hot-section___zh",
    path: "/zh/section/components/hot-section",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/section/components/hot-section.vue")
  },
  {
    name: "section-components-section-chart___en",
    path: "/en/section/components/section-chart",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/section/components/section-chart.vue")
  },
  {
    name: "section-components-section-chart___zh___default",
    path: "/section/components/section-chart",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/section/components/section-chart.vue")
  },
  {
    name: "section-components-section-chart___zh",
    path: "/zh/section/components/section-chart",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/section/components/section-chart.vue")
  },
  {
    name: "section-components-section-platform___en",
    path: "/en/section/components/section-platform",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/section/components/section-platform.vue")
  },
  {
    name: "section-components-section-platform___zh___default",
    path: "/section/components/section-platform",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/section/components/section-platform.vue")
  },
  {
    name: "section-components-section-platform___zh",
    path: "/zh/section/components/section-platform",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/section/components/section-platform.vue")
  },
  {
    name: "section___en",
    path: "/en/section",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/section/index.vue")
  },
  {
    name: "section___zh___default",
    path: "/section",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/section/index.vue")
  },
  {
    name: "section___zh",
    path: "/zh/section",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/section/index.vue")
  },
  {
    name: "selection-components-addFilterCondition___en",
    path: "/en/selection/components/addFilterCondition",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/components/addFilterCondition.vue")
  },
  {
    name: "selection-components-addFilterCondition___zh___default",
    path: "/selection/components/addFilterCondition",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/components/addFilterCondition.vue")
  },
  {
    name: "selection-components-addFilterCondition___zh",
    path: "/zh/selection/components/addFilterCondition",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/components/addFilterCondition.vue")
  },
  {
    name: "selection-components-mkt-valueViewer___en",
    path: "/en/selection/components/mkt-valueViewer",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/components/mkt-valueViewer.vue")
  },
  {
    name: "selection-components-mkt-valueViewer___zh___default",
    path: "/selection/components/mkt-valueViewer",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/components/mkt-valueViewer.vue")
  },
  {
    name: "selection-components-mkt-valueViewer___zh",
    path: "/zh/selection/components/mkt-valueViewer",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/components/mkt-valueViewer.vue")
  },
  {
    name: "selection-favorite___en",
    path: "/en/selection/favorite",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/favorite.vue")
  },
  {
    name: "selection-favorite___zh___default",
    path: "/selection/favorite",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/favorite.vue")
  },
  {
    name: "selection-favorite___zh",
    path: "/zh/selection/favorite",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/favorite.vue")
  },
  {
    name: "selection___en",
    path: "/en/selection",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/index.vue")
  },
  {
    name: "selection___zh___default",
    path: "/selection",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/index.vue")
  },
  {
    name: "selection___zh",
    path: "/zh/selection",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/selection/index.vue")
  },
  {
    name: "skindiy-components-chooseSkinDialog___en",
    path: "/en/skindiy/components/chooseSkinDialog",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseSkinDialog.vue")
  },
  {
    name: "skindiy-components-chooseSkinDialog___zh___default",
    path: "/skindiy/components/chooseSkinDialog",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseSkinDialog.vue")
  },
  {
    name: "skindiy-components-chooseSkinDialog___zh",
    path: "/zh/skindiy/components/chooseSkinDialog",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseSkinDialog.vue")
  },
  {
    name: "skindiy-components-chooseStickerDialog___en",
    path: "/en/skindiy/components/chooseStickerDialog",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseStickerDialog.vue")
  },
  {
    name: "skindiy-components-chooseStickerDialog___zh___default",
    path: "/skindiy/components/chooseStickerDialog",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseStickerDialog.vue")
  },
  {
    name: "skindiy-components-chooseStickerDialog___zh",
    path: "/zh/skindiy/components/chooseStickerDialog",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/chooseStickerDialog.vue")
  },
  {
    name: "skindiy-components-item___en",
    path: "/en/skindiy/components/item",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/item.vue")
  },
  {
    name: "skindiy-components-item___zh___default",
    path: "/skindiy/components/item",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/item.vue")
  },
  {
    name: "skindiy-components-item___zh",
    path: "/zh/skindiy/components/item",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/components/item.vue")
  },
  {
    name: "skindiy___en",
    path: "/en/skindiy",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/index.vue")
  },
  {
    name: "skindiy___zh___default",
    path: "/skindiy",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/index.vue")
  },
  {
    name: "skindiy___zh",
    path: "/zh/skindiy",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/skindiy/index.vue")
  },
  {
    name: "status___en",
    path: "/en/status",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/status.vue")
  },
  {
    name: "status___zh___default",
    path: "/status",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/status.vue")
  },
  {
    name: "status___zh",
    path: "/zh/status",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/status.vue")
  },
  {
    name: "statuscomponents-charts___en",
    path: "/en/statuscomponents/charts",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/statuscomponents/charts.vue")
  },
  {
    name: "statuscomponents-charts___zh___default",
    path: "/statuscomponents/charts",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/statuscomponents/charts.vue")
  },
  {
    name: "statuscomponents-charts___zh",
    path: "/zh/statuscomponents/charts",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/statuscomponents/charts.vue")
  },
  {
    name: "terms-type___en",
    path: "/en/terms/:type()",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/terms/[type].vue")
  },
  {
    name: "terms-type___zh___default",
    path: "/terms/:type()",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/terms/[type].vue")
  },
  {
    name: "terms-type___zh",
    path: "/zh/terms/:type()",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/terms/[type].vue")
  },
  {
    name: "test___en",
    path: "/en/test",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/test.vue")
  },
  {
    name: "test___zh___default",
    path: "/test",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/test.vue")
  },
  {
    name: "test___zh",
    path: "/zh/test",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/test.vue")
  },
  {
    name: "trend___en",
    path: "/en/trend",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/index.vue")
  },
  {
    name: "trend___zh___default",
    path: "/trend",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/index.vue")
  },
  {
    name: "trend___zh",
    path: "/zh/trend",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/index.vue")
  },
  {
    name: "trend-indexconpoment-dataItem___en",
    path: "/en/trend/indexconpoment/dataItem",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/dataItem.vue")
  },
  {
    name: "trend-indexconpoment-dataItem___zh___default",
    path: "/trend/indexconpoment/dataItem",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/dataItem.vue")
  },
  {
    name: "trend-indexconpoment-dataItem___zh",
    path: "/zh/trend/indexconpoment/dataItem",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/dataItem.vue")
  },
  {
    name: "trend-indexconpoment-trend___en",
    path: "/en/trend/indexconpoment/trend",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/trend.vue")
  },
  {
    name: "trend-indexconpoment-trend___zh___default",
    path: "/trend/indexconpoment/trend",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/trend.vue")
  },
  {
    name: "trend-indexconpoment-trend___zh",
    path: "/zh/trend/indexconpoment/trend",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/trend/indexconpoment/trend.vue")
  },
  {
    name: "update___en",
    path: "/en/update",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/update.vue")
  },
  {
    name: "update___zh___default",
    path: "/update",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/update.vue")
  },
  {
    name: "update___zh",
    path: "/zh/update",
    component: () => import("/root/workspace/steamdt.com_3as3/pages/update.vue")
  }
]